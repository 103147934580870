import { FIRST_NAME, LAST_NAME, USER_DESIGNATION, USER_ID, USER_ROLES, USER_EMAIL } from '../Redux/Redux.constants';
import { capitalizeFirstLetter } from './dummyUtils';

export const SetLocalStorage = inputObject => {
    for (let item in inputObject)
        localStorage.setItem(
            item,
            setItemValue(inputObject[item])
        );
};

export const SetCookie = inputObject => {
    document.cookie = `${inputObject}; path=/; domain=.${window.location.host}; secure; SameSite=None`;
};

export const GetLocalStorage = key => {
    return getItemValue(localStorage.getItem(key));
};
export const getUserDetailsFromStorage = key => {
    const firstName = getItemValue(localStorage.getItem(FIRST_NAME));
    const lastName = getItemValue(localStorage.getItem(LAST_NAME));
    return {
        firstName,
        lastName,
        fullName: firstName + ' ' + lastName,
        email: getItemValue(localStorage.getItem(USER_EMAIL)),
        designation: getItemValue(localStorage.getItem(USER_DESIGNATION)),
        userId: getItemValue(localStorage.getItem(USER_ID)),
        roles: getItemValue(localStorage.getItem(USER_ROLES)),
    };
};

function setItemValue(value) {
    if (typeof value != 'string') return JSON.stringify(value);
    else return value;
}
function getItemValue(item) {
    try {
        return JSON.parse(item);
    } catch (err) {
        return item;
    }
}

export function getClientName() {
    return capitalizeFirstLetter(GetLocalStorage('clientName').replace('-', ' '));
}

export const CheckInLocalStorage = key => {
    return localStorage.getItem(key) ? true : false;
};

export const ClearLocalStorage = () => {
    const client = GetLocalStorage('clientName');
    localStorage.clear();
    localStorage.setItem('clientName', client);
};
